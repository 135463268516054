






















































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Widget } from "@/models/Widget";
import { Template } from "@/models/Template";
import { Region } from "@/models/Region";
import { IndicatorTable } from "@/models/Indicator";
import {
  WIDGET_REQUEST,
  WIDGET_UPDATE,
  WIDGET_CREATE,
  WIDGET_DELETE,
  WIDGET_UPDATED,
  WIDGET_LOADED,
  WIDGET_VERIFY,
} from "@/store/actions/widget";
import { TEMPLATE_REQUEST, TEMPLATE_LOADED } from "@/store/actions/template";
import {
  INDICATOR_TABLE_REQUEST,
  INDICATOR_TABLE_LOADED,
} from "@/store/actions/indicatorTable";
import { REGION_REQUEST, REGION_LOADED } from "@/store/actions/main";
import ChartPreview from "@/components/ChartPreview.vue";
import { EChartTypes } from "@/misc/Enums";
@Component({
  components: {
    ChartPreview,
  },
})
export default class Widgets extends Vue {
  private widgets: Widget[] = [];
  private regions: any = [];
  private templates: Template[] = [];
  private indicatorsTables: IndicatorTable[] = [];
  private totalValue = null as any;
	private totalTitle = null as any;
  private widgetsHeaders = [
    {
      text: "ID",
      value: "id",
      align: "start",
      width: "5%",
    },
    {
      text: "Название",
      value: "widget_name",
      align: "start",
      width: "15%",
    },
    {
      text: "Регион",
      value: "region_id",
      align: "start",
      width: "15%",
    },
    {
      text: "Таблица индикаторов",
      value: "indicator_table_id",
      align: "start",
      width: "15%",
    },
    {
      text: "Шаблон",
      value: "template_id",
      align: "start",
      width: "15%",
    },
    {
      text: "Местоположение",
      value: "widget_position",
      align: "start",
      width: "5%",
    },
    {
      text: "Поля",
      value: "widget_fields.length",
      align: "start",
      width: "5%",
    },
    {
      text: "Верификация",
      value: "verified",
      align: "start",
      width: "5%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
    
  ];
  private loading: boolean = false;
  private previewDialog: boolean = false;
  private chartType: EChartTypes = EChartTypes.Diagram;
  private previewLabels = [];
  private previewData = [];
  private previewColors = [] as any;
  private previewName = "";
  private search = '';
  private itemToDelete: Widget = new Widget();
  private deleteDialog: boolean = false;
	private deleteSnackbar: boolean = false;
  private deleteAsk: boolean = false;
  //verify
  private verifyingItems: number[] = [];
  private verifier: boolean = false;
  private deleteDisabled: boolean = false;
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;
  //viewModel
  private widgetsToggleView = 0;
  private widgetsRows = [] as any;
  private widgetsItems = [] as any;
  private widgetsEmpty = [{empty: true}] as any;
  private widgetsPosition = [] as any;
  // проверка на то является ли значение числом
  private reg = /^[0-9,.]*$/;

  $refs!: {
    chart: any;
  };
  //Initialise methods
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = new Widget();
  }
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.WIDGET_TABLE,
        this.$store.getters.WIDGET_LOADED
      )
    );
    needReload.push(
      this.needReload(
        this.$store.getters.TEMPLATE_TABLE,
        this.$store.getters.TEMPLATE_LOADED
      )
    );
    needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    needReload.push(
      this.needReload(
        this.$store.getters.INDICATOR_TABLE_TABLE,
        this.$store.getters.INDICATOR_TABLE_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;

    if (
      this.needReload(
        this.$store.getters.WIDGET_TABLE,
        this.$store.getters.WIDGET_LOADED
      )
    ) {
      this.$store.dispatch(WIDGET_REQUEST).then(() => {
        this.initIfReady();
      });
    }
    if (
      this.needReload(
        this.$store.getters.TEMPLATE_TABLE,
        this.$store.getters.TEMPLATE_LOADED
      )
    ) {
      this.$store.dispatch(TEMPLATE_REQUEST).then(() => {
        this.initIfReady();
      });
    }
    if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
    if (
      this.needReload(
        this.$store.getters.INDICATOR_TABLE_TABLE,
        this.$store.getters.INDICATOR_TABLE_LOADED
      )
    ) {
      this.$store.dispatch(INDICATOR_TABLE_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    console.log("INIT SELF");
    this.widgets = this.$store.getters.WIDGET_TABLE.data;
    this.templates = this.$store.getters.TEMPLATE_TABLE.data;
    this.regions = this.$store.getters.REGION_TABLE.data;
    this.indicatorsTables = this.$store.getters.INDICATOR_TABLE_TABLE.data;
    if (localStorage.getItem("role") == "Verifer"){
      this.verifier = true;
    }
    let maxRow = 0;
    for(let i = 0; i < this.widgets.length; i++) {
      this.pushItem(this.widgets[i]);
      this.widgetsPosition.push(this.widgets[i].widget_position);
      if (this.widgets[i].widget_position != undefined && this.widgets[i].widget_position!.length == 2) {
        let widRowPos = this.widgets[i].widget_position![0];
        if (widRowPos > maxRow) { 
          maxRow = widRowPos;
        }
      }
    }
    let abc = 0 as number;
    for(let i = 0; i < maxRow; i++) {
      let row = [] as any;
      for (let k = 1; k <= 3; k++) {
        let completed = false;
        this.widgetsPosition.forEach((item, key) => {
          if (item[0]==i+1 && item[1]==k) {
            row.push(this.widgetsItems[key]);
            console.log(item,"item");
            completed = true;
          }
        });
        if (!completed) {
          row.push([]);
        }
      }
      this.widgetsRows.push(row);
    }
    


    this.loading = false;
    console.log("loading");
  }
  //Methods
  private verify(item){
    let v = item.verified as boolean;
    v = !v;
    let data = {
      id: +item.id,
      params: {
        v: v,
      }
    }
    this.verifyingItems.push(+item.id);
    this.$store.dispatch(WIDGET_VERIFY, data).then(() => {
      this.tryToInitSelf();
      for(let i = 0; i < this.verifyingItems.length; i++){
        if(+item.id == this.verifyingItems[i]){
          this.verifyingItems.splice(i, 1);
        }
      }
    });
  }
  private isVerifying(id){
    if(!this.verifier)
      return true;
    for(let i = 0; i < this.verifyingItems.length; i++){
      if(id == this.verifyingItems[i]){
        return true;
      }
    }
    return false;
  }
  private getRegionName(id) {
    for (let i = 0; i < this.regions.length; i++) {
      if (this.regions[i].id == id) return this.regions[i].name;
    }
    return "";
  }
  private getIndicatorTableName(id) {
    for (let i = 0; i < this.indicatorsTables.length; i++) {
      if (this.indicatorsTables[i].id == id)
        return this.indicatorsTables[i].indicator_table_name;
    }
    return "";
  }
  private getTemplateName(id) {
    for (let i = 0; i < this.templates.length; i++) {
      if (this.templates[i].id == id) return this.templates[i].template_name;
    }
    return "";
  }
  private toCreate() {
    //localStorage.removeItem("widgetToEdit");
    this.$router.push({ name: "WidgetsCreate" });
  }
  private toEdit(item) {
    console.log("item: ", item);
    const parsed = JSON.stringify(item);
    localStorage.setItem("widgetToEdit", parsed);
    this.$router.push({ name: "WidgetsUpdate", params: { id: item.id } });
  }
  private deleteItem(item:Widget, confirmation:boolean) {
    this.itemToDelete = item;
    if(confirmation)
    {
			this.deleteDialog = true;
		}
    else
		{
      console.log(item);
			console.log("Delete!");
      this.$store.dispatch(WIDGET_DELETE, item.id).then(() => {
        this.tryToInitSelf();
				this.deleteSnackbar = true;
        this.itemToDelete = new Widget();
        this.deleteDisabled = false;
      });
      this.deleteDialog = false;
    }
  }
  private viewItem(widget: any) {
    let labels = [] as any;
    let data = [] as any;
    let colors = [] as any;
    let template = {} as any;
    for (let i = 0; i < this.templates.length; i++) {
      if (widget.template_id == this.templates[i].id)
        template = this.templates[i];
    }
    let isExist = false;
    widget.widget_fields.forEach((item, key) => {
      if(item.template_field.key_name == 'total'){
        isExist = true;
        this.totalTitle = item.field_title;
        this.totalValue = +item.field_value;
      }
      else{
        let isNumber = this.reg.test(item.field_value);
        console.log(item.field_value);
        console.log("isNumber",isNumber);

        labels.push(item.field_title);
        colors.push(item.key_color);
        if(isNumber)
          data.push(+item.field_value);
        else
          data.push(item.field_value);
      }
    });
    if(!isExist){
      this.totalTitle = null;
      this.totalValue = null;
    }
	  this.chartType = template.widget_type;
    this.previewColors = colors;
    this.previewLabels = labels;
    this.previewData = data;
    this.previewName = widget.widget_name;
    this.previewDialog = true;
    console.log("chart type", this.chartType);
    console.log("lab", labels);
    console.log("pr", data);
    this.redrawChart();
  }
  private redrawChart() {
    if (this.chartType !== undefined) {
      var wt = +this.chartType;
    }
    this.chartType = 4;
    setTimeout(() => {
      this.chartType = wt;
      this.$refs.chart.redraw();
	  console.log("c type", this.chartType);
    }, 1);
  }
  private pushItem(widget: any) {
    let labels = [] as any;
    let data = [] as any;
    let colors = [] as any;
    let template = {} as any;
    let tt;
    let tv;
    for (let i = 0; i < this.templates.length; i++) {
      if (widget.template_id == this.templates[i].id)
        template = this.templates[i];
    }
    let isExist = false;
    widget.widget_fields.forEach((item, key) => {
      if(item.template_field.key_name == 'total'){
        isExist = true;
        tt = item.field_title;
        tv = +item.field_value;
      }
      else{
        let isNumber = this.reg.test(item.field_value);
        console.log(item.field_value);
        console.log("isNumber",isNumber);
        labels.push(item.field_title);
        colors.push(item.key_color);
        if(isNumber)
          data.push(+item.field_value);
        else
          data.push(item.field_value);
      }
    });
    if(!isExist){
      tt = null;
      tv = null;
    }
    this.widgetsItems.push({
      totalTitle: tt,
      totalValue: tv,
      chartType: template.widget_type,
      previewColors: colors,
      previewLabels: labels,
      previewData: data,
      previewName: widget.widget_name,
      previewDialog: true,
    })
    this.redrawChart();
  }
  //Hooks
  private mounted() {
    this.tryToInitSelf();
  }
  private nameSearch(value, search, item): boolean {
    return value != null &&
      search != null &&
      typeof value === 'string' &&
      value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
  }
}
